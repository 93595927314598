import { GhettoStream, MuLawDecoder, PcmAudioPlayer, processStream } from './processStream';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import H264Controls from './H264Controls';
import JMuxer from 'jmuxer';
import dayjs from 'dayjs';
import { useHistoryVideo } from '@/app/main/subadmin/livestreaming/historycamera/HistoryProvider';

const H264VideoPlayer = ({
	url,
	getHistoryVideo,
	roomData,
	selectedDate,
	startTime,
	setStreamData,
	isLoading,
	setIsLoading,
}) => {
	const playerRef = useRef(null);
	const boxRef = useRef(null);
	const { volume, setVolume } = useHistoryVideo()
	const [initialSelectedStartTime, setInitialSelectedStartTime] = useState(startTime);
	const [error, setError] = useState('');
	const [isSeeking, setIsSeeking] = useState(false);
	const [selectedSeekTime, setSelectedSeekTime] = useState(0);
	const [pendingSeekTime, setPendingSeekTime] = useState(0);
	const [seekTime, setSeekTime] = useState(0);
	const [showControls, setShowControls] = useState(false);
	const [isBuffering, setIsBuffering] = useState(false);
	const pcmPlayerRef = useRef(null);
	// TODO: delete OR implement hover time label on progress bar if requested
	// const [hoveredValue, setHoveredValue] = useState(null);
	// const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
	let hoverTimeout;

	const attachEventListeners = useCallback(() => {
		boxRef.current.addEventListener('mouseenter', () => {
			clearTimeout(hoverTimeout);
			setShowControls(true);
		});
		boxRef.current.addEventListener('mouseleave', () => {
			hoverTimeout = setTimeout(() => {
				setShowControls(false);
			}, 2500);
		});

		boxRef.current.addEventListener('touchstart', () => {
			clearTimeout(hoverTimeout);
			setShowControls(true);
		});
		boxRef.current.addEventListener('touchend', () => {
			hoverTimeout = setTimeout(() => {
				setShowControls(false);
			}, 2000);
		});
	}, []);

	useEffect(() => {
		return () => {
			clearTimeout(hoverTimeout);
		};
	}, []);

	const isVideoPlaying = (video) => {
		if (video) {
			return !!(
				video.currentTime > 0 &&
				!video.paused &&
				!video.ended &&
				video.readyState > video.HAVE_FUTURE_DATA
			);
		}
		return false;
	};

	const callStreamEnded = useCallback((abortController) => {
		return (reader) => {
			if (abortController) {
				abortController.abort();
			}

			if (reader) {
				const cancelPromise = reader.cancel('Streaming canceled');
				if (cancelPromise && cancelPromise['catch'])
					cancelPromise['catch'](function (e) {
						if (DOMException && DOMException.ABORT_ERR && e && e.code === DOMException.ABORT_ERR) {
							// Expected result. Don't spam console.
						} else if (
							DOMException &&
							DOMException.INVALID_STATE_ERR &&
							e &&
							e.code === DOMException.INVALID_STATE_ERR
						) {
							// Expected result in MS Edge.
						} else {
							console.error(e);
						}
					});
				reader = null;
			}
			if (pcmPlayerRef.current) {
				pcmPlayerRef.current.Reset();
			}
		};
	}, []);

	const callFrameCallback = useCallback((jmuxer, pcmPlayer, muLawDecoder, player) => {
		let init = false;

		return (frame) => {
			if (frame.isVideo) {
				jmuxer.feed({
					video: frame.frameData,
				});

				// Start playing once we have some data streaming in.
				if (!init && !isVideoPlaying(player)) {
					const playPromise = player.play();

					if (playPromise !== undefined) {
						playPromise.then(() => {}).catch(() => {});
					}

					init = true;
				}
			} else if (frame.isAudio) {
				// The only supported format is mu-law encoding with one audio channel.
				if (frame.format.wFormatTag === 7 && frame.format.nChannels === 1) {
					// 7 is mu-law, mu-law is 8 bits per sample but decodes to 16 bits per sample.
					var pcm16Bit = muLawDecoder.DecodeUint8ArrayToFloat32Array(frame.frameData);
					pcmPlayer.AcceptBuffer([pcm16Bit], frame.format.nSamplesPerSec);
				} else if (frame.format.wFormatTag === 61868) {
					pcmPlayer.DecodeAndPlayAudioData(frame.frameData, frame.format.nSamplesPerSec);
				} else {
					console.log('Unsupported audio frame format', frame.format, frame);
				}
			}
		};
	}, []);

	useEffect(() => {
		if (pcmPlayerRef.current) {
			pcmPlayerRef.current.SetVolume(volume); // update volume separately here to avoid video interruption
		}
	}, [volume]);

	const fetchStream = useCallback(
		(url, fetchArgs, player, abortController, volume) => {
			const stream = new GhettoStream();
			const jmuxer = new JMuxer({
				node: player,
				mode: 'video',
				maxDelayMs: 1000,
				flushingTime: 1,
				clearBuffer: true,
				debug: false,
				fps: 30,
			});
			
			const pcmPlayer = new PcmAudioPlayer();
			// save the pcmPlayer to the ref so volume/play/pause can be toggled from the controls
			pcmPlayerRef.current = pcmPlayer;
			const muLawDecoder = new MuLawDecoder();
			pcmPlayer.SetAudioVolumeFromSettings(volume);

			const onEndStream = callStreamEnded(abortController);
			const frameCallback = callFrameCallback(jmuxer, pcmPlayer, muLawDecoder, player);

			fetch(url, fetchArgs)
				.then((res) => {
					const contentType = res.headers.get('Content-Type');
					switch (res.status) {
						case 200:
							if (contentType !== 'video/mpeg') {
								setError('Invalid stream type requested');
								onEndStream();
								return;
							}
							setError('');
							const reader = res.body.getReader();
							return processStream(stream, reader, frameCallback, onEndStream, volume);
						case 403:
							setError('Session has been lost');
							break;
						case 503:
							setError('Service is unavailable');
							break;
						default:
							setError('Server is unreachable');
							break;
					}
					onEndStream();
				})
				.catch((e) => {
					if (e === 'New time selected') {
						return;
					}
					setError('Server is unreachable');
					console.error('Fetch error:', e);
					onEndStream();
				})
				.finally(() => setIsLoading(false));
		},
		[callStreamEnded, callFrameCallback]
	);

	const updateStream = useCallback(
		(newTimeInSeconds) => {
			if (roomData && initialSelectedStartTime === startTime) {
				setIsLoading(true);
				const newStartTime = dayjs(`${selectedDate} ${startTime}`)
					.add(newTimeInSeconds, 'second')
					.format('HH:mm:ss');
				const endTimeFormatted = dayjs(`${selectedDate} ${startTime}`).add(20, 'minute').format('HH:mm:ss');

				getHistoryVideo(`${selectedDate} ${newStartTime}`, `${selectedDate} ${endTimeFormatted}`, roomData.id)
					.then((res) => {
						setStreamData(res?.data);
						setSelectedSeekTime(() => newTimeInSeconds);
					})
					.catch((err) => console.error('Error fetching stream:', err))
					.finally(() => setIsLoading(false));
			}
		},
		[getHistoryVideo, initialSelectedStartTime, roomData, selectedDate, setStreamData, startTime]
	);

	useEffect(() => {
		const abortController = new AbortController();
		const videoElement = playerRef.current;

		if (url) {
			attachEventListeners();

			if (videoElement && initialSelectedStartTime === startTime) {
				const fetchArgs = { credentials: 'same-origin', signal: abortController.signal };
				fetchStream(url, fetchArgs, videoElement, abortController, volume);

				videoElement.onloadeddata = () => {
					videoElement
						.play()
						.then(() => setIsBuffering(false))
						.catch((e) => console.error('Error attempting to play video:', e));
				};

				videoElement.onwaiting = () => {
					setIsBuffering(true);
				};

				videoElement.oncanplay = () => {
					if (isBuffering) {
						setIsBuffering(false);
						videoElement
							.play()
							.then(() => {
								setIsBuffering(false);
							})
							.catch((e) => console.error('Error attempting to resume video:', e));
					}
				};
			}

			return () => {
				abortController.abort('New time selected');
				videoElement.onloadeddata = null;
				videoElement.onwaiting = null;
				videoElement.oncanplay = null;
			};
		}
	}, [url, fetchStream, startTime, playerRef.current]);

	useEffect(() => {
		const videoElement = playerRef.current;
		let animationFrameId;

		const updatePlaybackTime = () => {
			animationFrameId = requestAnimationFrame(updatePlaybackTime);
		};

		if (videoElement) {
			updatePlaybackTime();
		}

		return () => {
			cancelAnimationFrame(animationFrameId);
		};
	}, [isSeeking]);

	useEffect(() => {
		if (!isLoading) {
			setIsSeeking(false);
		}
	}, [isLoading, seekTime]);

	const handleSliderChange = (event, newValue) => {
		setPendingSeekTime(newValue);
		setIsSeeking(true);
	};

	const handleSliderChangeCommitted = (event, newValue) => {
		const videoElement = playerRef.current;
		videoElement.pause();
		setIsSeeking(false);
		setSeekTime(newValue);
		updateStream(newValue);
	};

	// handles keeping video and audio in sync
	useEffect(() => {
		const videoElement = playerRef.current;

		if (videoElement) {
			videoElement.onpause = () => {
				if (pcmPlayerRef.current) {
					pcmPlayerRef.current.Pause();
				}
			};

			videoElement.onplay = () => {
				if (pcmPlayerRef.current) {
					pcmPlayerRef.current.Resume();
				}
			};
		}

		return () => {
			if (videoElement) {
				videoElement.onpause = null;
				videoElement.onplay = null;
			}
		};
	}, [playerRef.current]);

	const handleSliderChangeStart = () => setIsSeeking(true);

	// TODO: delete OR implement hover time label on progress bar if requested
	// const hoveredTime = useMemo(() => {
	// 	return dayjs(startTime, 'HH:mm:ss').add(hoveredValue, 'second').format('H:mm:ss');
	// }, [hoveredValue, startTime]);

	useEffect(() => {
		if (initialSelectedStartTime !== startTime) {
			setInitialSelectedStartTime(startTime);
			setSelectedSeekTime(0);
		}
	}, [startTime, initialSelectedStartTime]);
	const handleVolumeChange = (newVolume) => {
		setVolume(newVolume);
		if (playerRef.current) {
			playerRef.current.volume = newVolume / 100; // volume is from 0-1 so convert to percentage
		}
		if (pcmPlayerRef.current) {
			pcmPlayerRef.current.SetVolume(newVolume / 100);
		}
	};

	const handleToggleAudioPlayback = () => {
		if (isVideoPlaying(playerRef?.current)) {
			pcmPlayerRef.current.Pause();
		} else {
			pcmPlayerRef.current.Resume();
		}
	};
	return (
		<>
			{error && <h3>{error}</h3>}

			<div className="relative overflow-hidden" ref={boxRef}>
				<video
					key={url}
					ref={playerRef}
					className="rounded-lg history-video-player"
					id="history-video"
					autoPlay
				/>

				<div className="mx-auto relative">
					<H264Controls
						room={roomData}
						playerRef={playerRef}
						boxRef={boxRef}
						isSeeking={isSeeking}
						showControls={showControls}
						selectedSeekTime={selectedSeekTime}
						handleSliderChange={handleSliderChange}
						handleSliderChangeCommitted={handleSliderChangeCommitted}
						handleSliderChangeStart={handleSliderChangeStart}
						startTime={startTime}
						pendingSeekTime={pendingSeekTime}
						isPlaying={isVideoPlaying(playerRef?.current)}
						volume={volume}
						toggleAudioPlayback={handleToggleAudioPlayback}
						onVolumeChange={handleVolumeChange}
					/>
				</div>
			</div>
		</>
	);
};

export default H264VideoPlayer;
