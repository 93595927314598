import React, { useState, useEffect, useRef } from 'react';
import Hls from 'hls.js';
import { Avatar, CircularProgress } from '@material-ui/core';
import * as Actions from '@/app/store/actions';
import { useDispatch } from 'react-redux';
import H264VideoPlayer from '@/app/customComponents/H264VideoPlayer/H264VideoPlayer';
import CustomControls from '../live/CustomControls';
import config from '../live/hlsconfig';
import { useHistoryVideo } from './HistoryProvider';

function HistoryStreaming({
	room,
	streamData,
	startTime,
	endTime,
	date,
	roomData,
	getHistoryVideo,
	setStreamData,
	isFullscreen,
	setIsFullscreen,
	playbackTime,
	setPlaybackTime,
	isPlaying,
	setIsPlaying,
}) {
	const { clipLoading } = useHistoryVideo();
	const [showControls, setShowControls] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const hoverTimeout = useRef(null);
	const [h264Url, setH264Url] = useState('');
	const playerRef = useRef(null);
	const boxRef = useRef(null);
	const dispatch = useDispatch();
	const hls = useRef(null); // Use a ref to keep Hls instance
	const isIphone = /iPhone|iPod/.test(navigator.userAgent);

	const handleTimeUpdate = () => {
		const video = playerRef.current;
		if (video) {
			setPlaybackTime(video.currentTime);
		}
	};

	const handleBuffering = () => {
		setIsLoading(true);
	};

	const attachEventListeners = () => {
		const video = playerRef.current;
		const controlsContainer = boxRef.current;
		if (video && controlsContainer) {
			video.addEventListener('waiting', handleBuffering);
			video.addEventListener('timeupdate', handleTimeUpdate);
			video.addEventListener('playing', () => {
				setIsPlaying(true);
				setIsLoading(false);
				clearTimeout(hoverTimeout.current);
				setShowControls(true);
			});
			video.addEventListener('pause', () => {
				setIsPlaying(false);
			});

			controlsContainer.addEventListener('mouseenter', () => {
				clearTimeout(hoverTimeout.current);
				setShowControls(true);
			});
			controlsContainer.addEventListener('mouseleave', () => {
				hoverTimeout.current = setTimeout(() => {
					setShowControls(false);
				}, 2500);
			});

			controlsContainer.addEventListener('touchstart', () => {
				clearTimeout(hoverTimeout.current);
				setShowControls(true);
			});
			controlsContainer.addEventListener('touchend', () => {
				hoverTimeout.current = setTimeout(() => {
					setShowControls(false);
				}, 2000);
			});
		}
	};

	const initializeHls = (url) => {
		const video = playerRef.current;
		if (hls.current) {
			hls.current.destroy();
			hls.current = null;
		}
		hls.current = new Hls(config);
		hls.current.loadSource(url);
		hls.current.attachMedia(video);
		hls.current.on(Hls.Events.MEDIA_ATTACHED, () => {
			hls.current.on(Hls.Events.ERROR, (event, data) => {
				dispatch(
					Actions.showMessage({
						message: 'Error loading video stream.',
						autoHideDuration: 1500,
						variant: 'error',
					})
				);
			});
			hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
				video.currentTime = playbackTime;
				if (isPlaying) {
					video.play().catch((error) => {
						console.warn('Failed to play video:', error);
					});
				}
			});
		});
		attachEventListeners();
	};

	useEffect(() => {
		const video = playerRef.current;
		if (streamData?.session_url) {
			if (video) {
				if (Hls.isSupported()) {
					initializeHls(streamData.session_url);
				} else {
					video.src = streamData.session_url;
				}
			} else if (streamData?.video_type === 'h264') {
				setH264Url(streamData?.session_url);
			}
		} else {
			setH264Url('');
		}

		return () => {
			if (streamData && streamData?.video_type === 'hls') {
				clearTimeout(hoverTimeout.current);
				if (hls.current) {
					hls.current.destroy();
					hls.current = null;
				}
				if (video) {
					video.removeEventListener('waiting', handleBuffering);
					video.removeEventListener('timeupdate', handleTimeUpdate);
					video.removeEventListener('playing', () => {
						clearTimeout(hoverTimeout.current);
						setShowControls(true);
					});
					video.removeEventListener('pause', () => {});
				}
			}
		};
	}, [streamData]);

	useEffect(() => {
		const video = playerRef.current;
		if (isFullscreen && video) {
			video.currentTime = playbackTime;
			if (isPlaying) {
				video.play().catch((error) => {
					console.warn('Failed to play video:', error);
				});
			}
		}
	}, [isFullscreen]);

	useEffect(() => {
		const video = playerRef.current;
		if (video) {
			video.currentTime = 0;
		}
	}, [startTime]);

	return (
		<div>
			{isIphone && h264Url ? (
				<div className="flex flex-col justify-center items-center mt-64">
					<p>History playback is not supported on iOS devices at this time</p>
					<p>Please use another device to view this video</p>
				</div>
			) : (
				<div
					className="flex justify-start items-start bg-black rounded-lg relative overflow-hidden"
					ref={boxRef}
				>
					<div className={`w-full h-full relative ${!isFullscreen ? 'aspect-ratio-16-9' : ''}`}>
						{h264Url ? (
							<H264VideoPlayer
								url={h264Url}
								playbackTime={playbackTime}
								setPlaybackTime={setPlaybackTime}
								isHistory={true}
								roomData={roomData}
								selectedDate={date}
								getHistoryVideo={getHistoryVideo}
								startTime={startTime}
								setStreamData={setStreamData}
								endTime={endTime}
								isLoading={isLoading}
								setIsLoading={setIsLoading}
							/>
						) : streamData?.session_url && streamData?.video_type === 'hls' ? (
							<div
								className={` ${
									isFullscreen
										? 'h-full w-full fixed top-0 left-0 z-9999'
										: 'relative overflow-hidden'
								}`}
							>
								<video
									id="myVideo"
									preload="none"
									className=" history-video-player object-fill"
									autoPlay
									ref={playerRef}
									muted
									playsInline
								/>
								{streamData?.overlay_image && (
									<img
										src={streamData?.overlay_image}
										className="absolute top-0 left-0 z-99 w-full h-full object-fill"
									/>
								)}
								{isLoading && (
									<div className="loading-spinner-container">
										<CircularProgress size={35} />
									</div>
								)}
								<div className="absolute bottom-0 w-full">
									<CustomControls
										room={room?.id}
										playerRef={playerRef}
										setIsFullscreen={setIsFullscreen}
										boxRef={boxRef}
										handleIphoneFullScreenToggle={() => {
											setIsFullscreen(!isFullscreen);
										}}
										showControls={showControls}
									/>
								</div>
								<div className="absolute text-white text-lg font-bold top-0 left-0 px-12 flex items-center py-32 h-28 gap-10">
									<Avatar src={room?.thumb} />
									<div>{room?.name}</div>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
					{!startTime ? (
						<div className="stream-not-running-text-history">Please select a clip</div>
					) : clipLoading ? (
						<div className="stream-not-running-text-history bg-black w-full h-full flex justify-center items-center">
							<CircularProgress size={35} />
						</div>
					) : startTime && !streamData?.session_url && !isLoading ? (
						<div className="stream-not-running-text-history">No video</div>
					) : (
						<></>
					)}
				</div>
			)}
		</div>
	);
}

export default HistoryStreaming;
