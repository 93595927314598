import FuseAnimate from '@/@fuse/core/FuseAnimate';
import FusePageSimple from '@/@fuse/core/FusePageSimple';
import { getHistoryVideo } from '@/app/services/liveStreaming/liveStreaming';
import { getRooms } from '@/app/services/rooms/rooms';
import * as Actions from '@/app/store/actions';
import { Portal, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import './historyCamera.css';
import HistoryStreaming from './HistoryStreaming';
import HistoryDrawer from './HistoryDrawer';
import { HistoryVideoProvider, useHistoryVideo } from './HistoryProvider';

const useStyles = makeStyles({
	layoutRoot: {},
	select: {
		'&:before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select.MuiSelect-select': {
			textAlign: 'left',
		},
		'&:after': {
			borderBottom: 'none',
		},
		'&:not(.Mui-disabled):hover::before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'inherit',
		},
	},
	dateStyle: {
		'& .MuiInput-root::after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline::before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover::before': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			cursor: 'pointer',
		},
		'& .MuiInput-root': {
			cursor: 'pointer',
		},
	},
	accordionSummaryStyle: {
		background: 'radial-gradient(circle, rgba(255, 255, 255, 1) 46%, rgba(235, 247, 254, 1) 68%)',
	},
});

function Historycamera() {
	const { clipLoading, setClipLoading } = useHistoryVideo();
	const [rooms, setRooms] = useState([]);
	const [selectedDate, setSelectedDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
	const [roomsLoading, setRoomsLoading] = useState(true);
	const [roomPage, setRoomPage] = useState(1);
	const [roomData, setRoomData] = useState(null);
	const [activeHourSlot, setActiveHourSlot] = useState(null);
	const [streamData, setStreamData] = useState({});
	const [selectedClip, setSelectedClip] = useState(null);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [startTime, setStartTime] = useState(0);
	const [endTime, setEndTime] = useState(dayjs().minute(0).second(0).format('HH:mm:ss'));
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [playbackTime, setPlaybackTime] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false);
	const containerRef = useRef(null);
	const isIphone = /iPhone|iPod/.test(navigator.userAgent);
	const showFixedDrawer = useMediaQuery('(min-width:1280px)');
	const mdUp = useMediaQuery('(min-width:960px)');
	const dispatch = useDispatch();
	const pageLayout = useRef(null);

	useEffect(() => {
		let isMounted = true;
		setRoomsLoading(true);
		getRooms('', roomPage)
			.then((res) => {
				if (!isMounted) return;
				setRooms([...rooms, ...res?.data?.data]);
				if (roomPage === 1) {
					setRoomData(res?.data?.data[0]);
				}
				if (res.data.current_page < res.data.last_page) {
					setRoomPage(roomPage + 1);
				}
				setRoomsLoading(false);
			})
			.catch((err) => {
				if (!isMounted) return;
				dispatch(
					Actions.showMessage({
						message: 'Failed to get rooms.',
						autoHideDuration: 1500,
						variant: 'error',
					})
				);
				setRoomsLoading(false);
			});
		return () => {
			isMounted = false;
		};
	}, [roomPage, dispatch]);

	const handleHourClick = (hour) => {
		setActiveHourSlot(hour);
		setSelectedClip(null);
		setEndTime(hour.end_time);
	};

	const handleClipSelect = (clip) => {
		setStartTime(clip.start_time);
		setSelectedClip(clip);
		setPlaybackTime(0);
		if (roomData) {
			setClipLoading(true);
			setEndTime(() => clip.end_time); // Set the end time based on the selected clip
			getHistoryVideo(`${selectedDate} ${clip?.start_time}`, `${selectedDate} ${clip?.end_time}`, roomData?.id)
				.then((res) => {
					setStreamData(res?.data);
					setClipLoading(false);
				})
				.catch((err) => {
					dispatch(
						Actions.showMessage({
							message: 'Something went wrong please try again',
							autoHideDuration: 1500,
							variant: 'error',
						})
					);
					setClipLoading(false);
				});
		}
	};

	const handleRoomChange = (e, v) => {
		setRoomData(v.props.value);
		setStartTime(0);
		setActiveHourSlot(null);
		setStreamData(null);
		setSelectedClip(null);
	};

	const handleToggleDrawer = () => {
		setDrawerOpen(!drawerOpen);
	};

	return (
		<div style={{ zIndex: -1 }}>
			<FusePageSimple
				content={
					<>
						<div className="pt-20 pb-64 w-full">
							<br />
							<FuseAnimate animation="transition.slideLeftIn" duration={600}>
								<div className="flex" style={{ maxWidth: '600px' }}>
									<div className="w-full">
										<div className="flex justify-between">
											<div className="flex items-start flex-col md:flex-row ">
												<h4 className="font-extrabold md:text-lg">
													{roomData ? `${roomData?.name}` : <span className=""></span>}
												</h4>
												<span className="md:text-lg md:ml-4">
													{selectedClip
														? ` ${mdUp ? ` - ` : ''}${dayjs(
																`${selectedDate} ${selectedClip?.start_time}`
														  ).format('MMMM D, YYYY h:mm A')} - ${dayjs(
																`${selectedDate} ${selectedClip?.end_time}`
														  ).format('h:mm A')}`
														: ''}
												</span>
											</div>
											{!showFixedDrawer && (
												<div onClick={handleToggleDrawer}>
													<button className="text-pd-blue font-bold flex items-center">
														<ChevronLeft className="mb-4" />
														Select Room & Time
													</button>
												</div>
											)}
										</div>

										<div
											className={`mt-20 w-full max-w-3xl ${showFixedDrawer ? ' pr-360' : ''}`}
											ref={containerRef}
										>
											<Portal
												container={
													isFullscreen && isIphone ? document.body : containerRef.current
												}
											>
												<div
													className={` ${
														isFullscreen
															? 'h-full w-full fixed top-0 left-0 z-9999'
															: 'relative overflow-hidden'
													}`}
												>
													<HistoryStreaming
														room={roomData}
														streamData={streamData}
														startTime={startTime}
														endTime={endTime}
														setStartTime={setStartTime}
														clipLoading={clipLoading}
														date={selectedDate}
														roomData={roomData}
														getHistoryVideo={getHistoryVideo}
														setStreamData={setStreamData}
														isFullscreen={isFullscreen}
														setIsFullscreen={setIsFullscreen}
														playbackTime={playbackTime}
														setPlaybackTime={setPlaybackTime}
														isPlaying={isPlaying}
														setIsPlaying={setIsPlaying}
													/>
												</div>
											</Portal>
										</div>
									</div>
								</div>
							</FuseAnimate>
						</div>
						<HistoryDrawer
							roomData={roomData}
							open={drawerOpen}
							setDate={setSelectedDate}
							setOpen={setDrawerOpen}
							showFixedDrawer={showFixedDrawer}
							handleRoomChange={handleRoomChange}
							rooms={rooms}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
							isLoading={roomsLoading}
							activeHourSlot={activeHourSlot}
							setActiveHourSlot={setActiveHourSlot}
							selectedClip={selectedClip}
							setSelectedClip={setSelectedClip}
							handleHourClick={handleHourClick}
							handleClipSelect={handleClipSelect}
							setStreamUrl={setStreamData}
						/>
					</>
				}
				innerScroll
				ref={pageLayout}
			/>
		</div>
	);
}

export default Historycamera;
